import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p align="center">
     <img src="images/myface.png" style={{
        "width": "200px",
        "height": "200px"
      }} />
    </p>
    <h1>{`Hi, I'm Aengus`}</h1>
    <p>{`Homeschooled and military raised, after graduating with a computer science degree from University of Washington I spent four years working on emerging markets and reliability for the Facebook Android app.`}</p>
    <p>{`I quit that job last year to redesign my life. Since then I have spent my time traveling, working to improve myself and my habits, and diving into a variety of side projects to decide how I want to spend my time.`}</p>
    <p>{`Currently my focus is on Android Apps and Game Development. I'm a contributing developer on `}<a parentName="p" {...{
        "href": "https://playhirt.com"
      }}>{`Hirt`}</a>{`, I have spent some time building the Gouda Engine as `}<a parentName="p" {...{
        "href": "https://cheddargames.com"
      }}>{`Cheddar Games`}</a>{`, and now I'm working on an Android app for audio note capture that I hope to write more about soon.`}</p>
    <p>{`Here on my site you can find my writings on education, audio, and programming.`}</p>
    <p>{`If you want to follow or contact me you can find me on `}<a parentName="p" {...{
        "href": "http://twitter.com/aengusmcmillin"
      }}>{`twitter`}</a>{`, `}<a parentName="p" {...{
        "href": "http://github.com/aengusmcmillin"
      }}>{`github`}</a>{`, and `}<a parentName="p" {...{
        "href": "mailto:aengusmcmillin@gmail.com"
      }}>{`email`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      